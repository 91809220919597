import React from 'react';
import { Button, TextLink } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { create, update } from '~/utils/api/resources/owner';
import { OwnerForEdit } from '~/models/owner';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { PATH } from '~/pages/owners/[id]';
import { EditorMode } from '~/components/common/Owners/Editor';
import Styles from './index.module.scss';

type Props = Readonly<{
  editorMode: EditorMode;
  isFullNameForm: boolean;
}>;

const parseFullName = (
  fullName: string | null
): { lastName: string; firstName: string } => {
  if (!fullName) return { lastName: '', firstName: '' };
  const [lastName, ...rest] = fullName.split(' ');
  return { lastName, firstName: rest.join(' ') };
};

const parseFullNameKana = (
  fullNameKana: string | null
): { lastNameKana: string; firstNameKana: string } => {
  if (!fullNameKana) return { lastNameKana: '', firstNameKana: '' };
  const [lastNameKana, ...restKana] = fullNameKana.split(' ');
  return { lastNameKana, firstNameKana: restKana.join(' ') };
};

export const OwnersEditorFooter: React.FC<Props> = ({
  editorMode,
  isFullNameForm
}: Props) => {
  const { id } = useRouter().query;
  const { getValues, handleSubmit, formState } = useFormContext<OwnerForEdit>();
  const router = useRouter();

  const processOwner = (
    owner: DeepReadonly<OwnerForEdit>
  ): DeepReadonly<OwnerForEdit> => {
    if (!owner.fullName && !owner.fullNameKana) {
      return owner;
    }

    if (isFullNameForm) {
      const { lastName, firstName } = parseFullName(owner.fullName);
      const { lastNameKana, firstNameKana } = parseFullNameKana(
        owner.fullNameKana
      );
      return {
        ...owner,
        lastName,
        firstName,
        lastNameKana,
        firstNameKana
      };
    }
    return { ...owner };
  };

  const submitCreate = async (): Promise<void> => {
    const owner: OwnerForEdit = getValues();
    const response = await create(processOwner(owner));
    if (isSuccess(response)) {
      if (response.data !== null) {
        await router.push(PATH(response.data.id));
      }
      toast.success('家主を新規登録しました');
      return;
    }
    toast.error(response.message);
  };
  const submitUpdate = async (): Promise<void> => {
    const owner: OwnerForEdit = getValues();
    const response = await update(processOwner(owner), Number(id));
    if (isSuccess(response)) {
      await router.push(PATH(Number(id)));
      toast.success('家主情報を修正しました');
      return;
    }
    toast.error(response.message);
  };
  const onClickSave = (): void => {
    handleSubmit(
      async () => {
        if (editorMode === 'new') {
          await submitCreate();
        } else {
          await submitUpdate();
        }
      },
      () => {
        const errorValues = Object.values(formState.errors);
        if (errorValues.length > 0) {
          const inputRef: HTMLInputElement | null =
            errorValues[0].ref instanceof HTMLInputElement
              ? errorValues[0].ref
              : null;
          if (inputRef !== null) {
            inputRef.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    )();
  };

  const getCancelHref = (): string => {
    if (editorMode === 'new') {
      return '/owners';
    }
    if (editorMode === 'edit' && id) {
      return `/owners/${id}`;
    }
    return '#';
  };

  return (
    <div className={Styles.FooterArea}>
      <span className={Styles.CancelButton}>
        <TextLink href={getCancelHref()} target="_self" variant="PrimaryBold">
          キャンセル
        </TextLink>
      </span>
      <span className={Styles.SaveButton}>
        <Button onClick={onClickSave} variant="primary" width="fill">
          {editorMode === 'new' ? '新規登録' : '修正を保存'}
        </Button>
      </span>
    </div>
  );
};
