import React from 'react';
import {
  DefinitionListItem,
  DefinitionListItemData,
  DefinitionListItemHeading
} from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import { displaySexName, Owner } from '~/models/owner';
import { getContentOrEmptyLabel } from '~/components/specifics/owners/[id]/components/Contents/Basic';
import { displayDate } from '~/utils/dateUtils';
import Styles from '../index.module.scss';

const displayKanaName = (owner: Owner): string => {
  if (!owner.lastNameKana && !owner.firstNameKana) {
    return '';
  }

  return `${owner.lastNameKana ?? ''} ${owner.firstNameKana ?? ''}`.trim();
};

export const OwnersContentsBasicIndividual: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }
  return (
    <>
      <DefinitionListItem>
        <DefinitionListItemHeading width={100}>氏名</DefinitionListItemHeading>
        <DefinitionListItemData>
          <div className={Styles.NameWrapper}>
            <div>
              {owner.lastName} {owner.firstName}
            </div>
            {displayKanaName(owner) !== '' && (
              <div>{displayKanaName(owner)}</div>
            )}
          </div>
        </DefinitionListItemData>
      </DefinitionListItem>
      <DefinitionListItem>
        <DefinitionListItemHeading>職業</DefinitionListItemHeading>
        <DefinitionListItemData>
          {getContentOrEmptyLabel(owner.job)}
        </DefinitionListItemData>
      </DefinitionListItem>
      <DefinitionListItem>
        <DefinitionListItemHeading>性別</DefinitionListItemHeading>
        <DefinitionListItemData>
          {getContentOrEmptyLabel(displaySexName(owner.sex))}
        </DefinitionListItemData>
      </DefinitionListItem>
      <DefinitionListItem>
        <DefinitionListItemHeading>生年月日</DefinitionListItemHeading>
        <DefinitionListItemData>
          {getContentOrEmptyLabel(
            owner.birthday ? displayDate(owner.birthday) : null
          )}
        </DefinitionListItemData>
      </DefinitionListItem>
    </>
  );
};
