import React from 'react';
import { TabMode } from '~/components/specifics/owners/[id]/components/Container';
import { NewOwnersContentsBasic } from '~/components/specifics/owners/[id]/components/NewContents/Basic';
import { OwnerWithContracts } from '~/models/owner';

type Props = DeepReadonly<{
  tabMode: TabMode;
  owner: OwnerWithContracts;
}>;

export const NewOwnersContents: React.FC<Props> = ({
  tabMode,
  owner
}: Props) => (
  <div>{tabMode === 'basic' && <NewOwnersContentsBasic owner={owner} />}</div>
);
