import React from 'react';
import { Input } from '@itandi/itandi-bb-ui';
import { OwnerCustomFieldSetting } from '~/models/ownerCustomFieldSetting';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';

type Props = DeepReadonly<{
  ownerCustomFieldSetting: OwnerCustomFieldSetting;
}>;

export const OwnersEditorBlockCustomFieldString: React.FC<Props> = ({
  ownerCustomFieldSetting
}: Props) => {
  const { register } = useFormContext<OwnerForEdit>();
  return (
    <Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(
        `ownerCustomFieldValues.${ownerCustomFieldSetting.fieldKey}`
      )}
      placeholder="例） テキスト"
    />
  );
};
