import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { Input, InputRadio, Tooltip, SubHeadLine } from '@itandi/itandi-bb-ui';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import Styles from '../index.module.scss';

export const NewOwnersEditorBlockContact: React.FC = () => {
  const { register, formState } = useFormContext<OwnerForEdit>();
  const { errors } = formState;

  return (
    <FormBlock>
      <div className={Styles.Title}>
        <SubHeadLine title="連絡先情報" />
      </div>
      <FormBlockRow
        errorMessage={errors.tel1?.message}
        label="電話番号1（メイン）"
      >
        <div className={Styles.DefaultRow}>
          <Input
            placeholder="例）09012341234"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('tel1')}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.tel2?.message} label="電話番号2">
        <div className={Styles.DefaultRow}>
          <Input
            placeholder="例）09012341234"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('tel2')}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.tel3?.message} label="電話番号3">
        <div className={Styles.DefaultRow}>
          <Input
            placeholder="例）090123412344"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('tel3')}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.email1?.message}
        label="メールアドレス1（メイン）"
      >
        <div className={Styles.DefaultRow}>
          <Input
            placeholder="例）itandi@example.com"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('email1')}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.email2?.message}
        label="メールアドレス2"
      >
        <div className={Styles.DefaultRow}>
          <Input
            placeholder="例）itandi@example.com"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('email2')}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.email3?.message}
        label="メールアドレス3"
      >
        <div className={Styles.DefaultRow}>
          <Input
            placeholder="例）itandi@example.com"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('email3')}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow label="送付先の住所">
        <div className={Styles.InputRadioGroup}>
          <InputRadio
            label="住所1"
            value="1"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('shippingAddressIndex')}
          />
          <InputRadio
            label="住所2"
            value="2"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('shippingAddressIndex')}
          />
          <InputRadio
            label="住所3"
            value="3"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('shippingAddressIndex')}
          />
          <Tooltip>
            「選択なし」かつ住所1が入力されている場合は、住所1が送付先の住所となります。
          </Tooltip>
        </div>
      </FormBlockRow>

      <FormBlockRow label="住所1">
        <div className={Styles.ZipcodeInputWrapper}>
          <Input
            placeholder="例）1140034"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('address1Zipcode')}
          />
          {errors.address1Zipcode != null && (
            <div className={Styles.ErrorMessage}>
              {errors.address1Zipcode.message}
            </div>
          )}
        </div>
        <div className={Styles.AddressFirstRow}>
          <div className={Styles.AddressLeftInput}>
            <Input
              placeholder="例）東京都"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1State')}
            />
            {errors.address1State != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1State.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <Input
              placeholder="例）港区"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1City')}
            />
            {errors.address1City != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1City.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.AddressSecondRow}>
          <div className={Styles.AddressLeftInput}>
            <Input
              placeholder="例）番地・丁目"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1Street')}
            />
            {errors.address1Street != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1Street.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <Input
              placeholder="例）以下の住所"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1Other')}
            />
            {errors.address1Other != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1Other.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>

      <FormBlockRow label="住所2">
        <div className={Styles.ZipcodeInputWrapper}>
          <Input
            placeholder="例）1140034"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('address2Zipcode')}
          />
          {errors.address2Zipcode != null && (
            <div className={Styles.ErrorMessage}>
              {errors.address2Zipcode.message}
            </div>
          )}
        </div>
        <div className={Styles.AddressFirstRow}>
          <div className={Styles.AddressLeftInput}>
            <Input
              placeholder="例）東京都"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2State')}
            />
            {errors.address2State != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2State.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <Input
              placeholder="例）港区"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2City')}
            />
            {errors.address2City != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2City.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.AddressSecondRow}>
          <div className={Styles.AddressLeftInput}>
            <Input
              placeholder="例）番地・丁目"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2Street')}
            />
            {errors.address2Street != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2Street.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <Input
              placeholder="例）以下の住所"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2Other')}
            />
            {errors.address2Other != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2Other.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>

      <FormBlockRow label="住所3">
        <div className={Styles.ZipcodeInputWrapper}>
          <Input
            placeholder="例）1140034"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('address3Zipcode')}
          />
          {errors.address3Zipcode != null && (
            <div className={Styles.ErrorMessage}>
              {errors.address3Zipcode.message}
            </div>
          )}
        </div>
        <div className={Styles.AddressFirstRow}>
          <div className={Styles.AddressLeftInput}>
            <Input
              placeholder="例）東京都"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3State')}
            />
            {errors.address3State != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3State.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <Input
              placeholder="例）港区"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3City')}
            />
            {errors.address3City != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3City.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.AddressSecondRow}>
          <div className={Styles.AddressLeftInput}>
            <Input
              placeholder="例）番地・丁目"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3Street')}
            />
            {errors.address3Street != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3Street.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <Input
              placeholder="例）以下の住所"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3Other')}
            />
            {errors.address3Other != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3Other.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>
    </FormBlock>
  );
};
