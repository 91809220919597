import React from 'react';
import { useRouter } from 'next/router';
import {
  MainHeadLine,
  DefinitionList,
  DefinitionListItem,
  DefinitionListItemData,
  DefinitionListItemHeading
} from '@itandi/itandi-bb-ui';
import { useOwners } from '~/hooks/api/useOwners';
import { RiMoreLine } from 'react-icons/ri';
import { displayDate } from '~/utils/dateUtils';
import Styles from '../index.module.scss';

type ExtraAttribute = {
  fieldType: string;
  fieldName: string;
  value: string | null;
};

type RowProps = DeepReadonly<{
  extraAttributes: ExtraAttribute[];
}>;

export const OwnerCustomFieldRow: React.FC<RowProps> = ({
  extraAttributes
}: RowProps) => {
  // NOTE: parseしないと'1'もしくは'0'がバックエンドから返ってくるためこのコンポーネント内で定義する。
  const displayBoolean = (ownerCustomFieldValue: string): string => {
    if (ownerCustomFieldValue === '1') {
      return 'チェック済み';
    }
    return '未チェック';
  };
  const displayValue = (
    fieldType: string,
    value: string | number | null
  ): string | number | null => {
    if (value === null || value === undefined || value === '') {
      return null;
    }
    switch (fieldType) {
      case 'date':
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return displayDate(value as string);
      case 'boolean':
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return displayBoolean(value as string);
      default:
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return value as string;
    }
  };
  return (
    <>
      {extraAttributes.map((attribute) => (
        <DefinitionListItem>
          <DefinitionListItemHeading width={200}>
            {attribute.fieldName}
          </DefinitionListItemHeading>
          <DefinitionListItemData key={`${attribute.fieldName}`}>
            {displayValue(attribute.fieldType, attribute.value) || (
              <span className={Styles.Empty}>未登録</span>
            )}
          </DefinitionListItemData>
        </DefinitionListItem>
      ))}
    </>
  );
};

export const OwnersContentsBasicCustomField: React.FC = () => {
  const { id } = useRouter().query;
  const { data: owner } = useOwners().useShow(id ? Number(id) : null);
  if (owner === null) return null;
  return (
    <div className={Styles.Card} id="antisocialCheckSection">
      <div className={Styles.CardTitleWrapper}>
        <MainHeadLine
          description="その他カスタムした項目"
          descriptionColor="Sub"
          icon={RiMoreLine}
          title="補足情報"
        />
      </div>
      <DefinitionList>
        <OwnerCustomFieldRow
          key={owner.id}
          extraAttributes={owner.ownerExtraAttributes}
        />
      </DefinitionList>
    </div>
  );
};
