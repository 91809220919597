import React from 'react';
import { OwnersPageTabs } from '~/components/specifics/owners/[id]/components/PageTabs';
import { NewOwnersPageTabs } from '~/components/specifics/owners/[id]/components/NewPageTabs';
import { OwnersContents } from '~/components/specifics/owners/[id]/components/Contents';
import { NewOwnersContents } from '~/components/specifics/owners/[id]/components/NewContents';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import { useFeatureFlag } from '~/components/specifics/settings/hooks/useFeatureFlag';
import Styles from './index.module.scss';

export type TabMode =
  | 'basic'
  | 'contracts'
  | 'contacts'
  | 'bank_accounts'
  | 'prints'
  | 'files';
export const OwnersContainer: React.FC = () => {
  const [useExperimentalOwnerFlag] = useFeatureFlag('useExperimentalOwnerFlag');

  const [tabMode, setTabMode] = React.useState<TabMode>('basic');
  const handleChangeTabMode = React.useCallback((newTabMode: TabMode): void => {
    setTabMode(newTabMode);
  }, []);
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }

  return (
    <>
      {useExperimentalOwnerFlag ? (
        <div className={Styles.NewContainer}>
          <div className={Styles.NewContents}>
            <NewOwnersContents owner={owner} tabMode={tabMode} />
          </div>
          <div className={Styles.NewTabArea}>
            <NewOwnersPageTabs
              currentTabMode={tabMode}
              handleChangeTabMode={handleChangeTabMode}
              owner={owner}
            />
          </div>
        </div>
      ) : (
        <div className={Styles.Container}>
          <div className={Styles.TabArea}>
            <OwnersPageTabs
              currentTabMode={tabMode}
              handleChangeTabMode={handleChangeTabMode}
              owner={owner}
            />
          </div>
          <div className={Styles.Contents}>
            <OwnersContents owner={owner} tabMode={tabMode} />
          </div>
        </div>
      )}
      ;
    </>
  );
};
