import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { SeisanKanrikunBank } from '~/models/seisanKanrikunBank';
import { buildUrlWithQuery } from '~/utils/urlQueryConverter';

const paths = {
  index: '/seisan_kanrikun/banks'
};

type SearchParams = DeepReadonly<{
  initial?: string;
}>;
type TIndex = (
  params: SearchParams
) => SwrReturnType<ReadonlyArray<SeisanKanrikunBank>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useSeisanKanrikunBanks = (): ReturnType => {
  const useIndex: TIndex = (params: SearchParams) => {
    const { data, mutate, error, isValidating } = useSWR<
      ReadonlyArray<SeisanKanrikunBank>
    >(params.initial ? buildUrlWithQuery(paths.index, params) : paths.index);
    return {
      data: data || null,
      mutate,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
