import React from 'react';
import { useRouter } from 'next/router';
import {
  MainHeadLine,
  DefinitionList,
  DefinitionListItem,
  DefinitionListItemData,
  DefinitionListItemHeading
} from '@itandi/itandi-bb-ui';
import { useOwners } from '~/hooks/api/useOwners';
import { RiBankLine } from 'react-icons/ri';
import Styles from '../index.module.scss';

// バックエンドでSKの口座情報を取得するAPIが未実装のため、仮で表示する
export const BankAccount: React.FC = () => {
  const { id } = useRouter().query;
  const { data: owner } = useOwners().useShow(id ? Number(id) : null);
  if (owner === null) return null;
  return (
    <div className={Styles.Card} id="bankAccountSection">
      <div className={Styles.CardTitleWrapper}>
        <MainHeadLine
          description="管理委託契約が複数のときは、その都度、送金手数料が1回差し引かれます"
          descriptionColor="Sub"
          icon={RiBankLine}
          title="銀行口座"
        />
      </div>
      <div>
        <DefinitionList direction="Row">
          <DefinitionListItem>
            <DefinitionListItemHeading>口座名義</DefinitionListItemHeading>
            <DefinitionListItemHeading>銀行</DefinitionListItemHeading>
            <DefinitionListItemHeading>支店</DefinitionListItemHeading>
            <DefinitionListItemHeading>預金種目</DefinitionListItemHeading>
            <DefinitionListItemHeading>口座番号</DefinitionListItemHeading>
          </DefinitionListItem>
          <DefinitionListItem>
            <DefinitionListItemData>（入力なし）</DefinitionListItemData>
            <DefinitionListItemData>（入力なし）</DefinitionListItemData>
            <DefinitionListItemData>（入力なし）</DefinitionListItemData>
            <DefinitionListItemData>（入力なし）</DefinitionListItemData>
            <DefinitionListItemData>（入力なし）</DefinitionListItemData>
          </DefinitionListItem>
        </DefinitionList>
      </div>
    </div>
  );
};
