import React from 'react';
import {
  DefinitionListItem,
  DefinitionListItemData,
  DefinitionListItemHeading
} from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import { getContentOrEmptyLabel } from '~/components/specifics/owners/[id]/components/Contents/Basic';
import Styles from '../index.module.scss';

export const OwnersContentsBasicCorp: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }
  return (
    <>
      <DefinitionListItem>
        <DefinitionListItemHeading>法人名</DefinitionListItemHeading>
        <DefinitionListItemData>
          <div className={Styles.NameWrapper}>
            <div>{owner.corpName}</div>
            {owner.corpNameKana !== null && owner.corpNameKana !== '' && (
              <div>{owner.corpNameKana}</div>
            )}
          </div>
        </DefinitionListItemData>
      </DefinitionListItem>
      <DefinitionListItem>
        <DefinitionListItemHeading>法人代表者名</DefinitionListItemHeading>
        <DefinitionListItemData>
          {getContentOrEmptyLabel(owner.ceoName)}
        </DefinitionListItemData>
      </DefinitionListItem>
      <DefinitionListItem>
        <DefinitionListItemHeading>法人代表者役職</DefinitionListItemHeading>
        <DefinitionListItemData>
          {getContentOrEmptyLabel(owner.ceoTitle)}
        </DefinitionListItemData>
      </DefinitionListItem>
      <DefinitionListItem>
        <DefinitionListItemHeading>法人担当者</DefinitionListItemHeading>
        <DefinitionListItemData>
          {getContentOrEmptyLabel(owner.corpContactName)}
        </DefinitionListItemData>
      </DefinitionListItem>
      <DefinitionListItem>
        <DefinitionListItemHeading>法人担当者備考</DefinitionListItemHeading>
        <DefinitionListItemData>
          {getContentOrEmptyLabel(owner.corpContactNote)}
        </DefinitionListItemData>
      </DefinitionListItem>
    </>
  );
};
