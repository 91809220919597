import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { Input, InputRadio, SubHeadLine } from '@itandi/itandi-bb-ui';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import Styles from '../index.module.scss';

const corpValues: { [field in string]: boolean } = {
  true: true,
  false: false
};

export const NewOwnersEditorBlockBasic: React.FC = () => {
  const { register, formState, setValue, watch } =
    useFormContext<OwnerForEdit>();
  const { errors } = formState;
  return (
    <FormBlock>
      <div className={Styles.Title}>
        <SubHeadLine title="基本情報" />
      </div>
      <FormBlockRow
        errorMessage={errors.corp?.message}
        label="個人・法人"
        required
      >
        <div className={Styles.InputRadioGroup}>
          <InputRadio
            checked={watch('corp') === false}
            isInvalid={errors.corp != null}
            label="個人"
            // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value } = e.target;
              setValue('corp', corpValues[value] ?? false);
            }}
            value="false"
          />
          <InputRadio
            checked={watch('corp') === true}
            isInvalid={errors.corp != null}
            label="法人"
            // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value } = e.target;
              setValue('corp', corpValues[value] ?? false);
            }}
            value="true"
          />
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.ownerKey?.message}
        label="家主管理番号"
      >
        <div className={Styles.DefaultRow}>
          <Input
            isInvalid={errors.ownerKey != null}
            placeholder="例）NS001"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('ownerKey')}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.qualifiedInvoiceIssuerRegistrationNumber?.message}
        label="適格請求書発行事業者 登録番号"
      >
        <div className={Styles.DefaultRow}>
          <Input
            isInvalid={errors.qualifiedInvoiceIssuerRegistrationNumber != null}
            placeholder="例）T1234567890123"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('qualifiedInvoiceIssuerRegistrationNumber')}
          />
        </div>
      </FormBlockRow>
    </FormBlock>
  );
};
