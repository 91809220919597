import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { SeisanKanrikunBankBranch } from '~/models/seisanKanrikunBankBranch';
import { buildUrlWithQuery } from '~/utils/urlQueryConverter';

const paths = {
  index: '/seisan_kanrikun/bank_branches'
};

type SearchParams = DeepReadonly<{
  bankCode: string;
  initial?: string;
}>;
type TIndex = (
  params: SearchParams
) => SwrReturnType<ReadonlyArray<SeisanKanrikunBankBranch>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useSeisanKanrikunBankBranches = (): ReturnType => {
  const useIndex: TIndex = (params: SearchParams) => {
    const { data, mutate, error, isValidating } = useSWR<
      ReadonlyArray<SeisanKanrikunBankBranch>
    >(params.bankCode ? buildUrlWithQuery(paths.index, params) : null);
    return {
      data: data || null,
      mutate,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
