import React from 'react';
import { ContractWithOwners } from '~/models/contract';
import { Input, Modal, Button, InputSelect } from '@itandi/itandi-bb-ui';
import {
  useSeisanKanrikunContracts,
  SearchParams,
  defaultSearchValue
} from '~/hooks/api/useSeisanKanrikunContracts';
import { SeisanKanrikunContract } from '~/models/seisanKanrikunContract';
import { update } from '~/utils/api/resources/seisanKanrikunContract';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';
import { InputDate } from '~/components/common/Form/Datepicker/InputDate';
import {
  convertDateWithNull,
  convertQueryStringDate,
  displayDate
} from '~/utils/dateUtils';
import { Owner } from '~/models/owner';
import { KeyedMutator } from 'swr';
import { ContractsCollaborationSeisanKanrikunNewLink } from '~/components/specifics/contracts/[id]/components/Collaboration/SeisanKanrikun/NewLink';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ContractsSeisanKanrikunCollaborationDataDisplayModal } from '~/components/specifics/contracts/[id]/components/Collaboration/SeisanKanrikun/CollaborationDataDisplayModal';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  collaboratedSeisanKanrikunContractMutate?: KeyedMutator<
    ReadonlyArray<SeisanKanrikunContract>
  >;
  isCollaborationModalOpen: boolean;
  handleCollaborationModalClose: () => void;
  contract: ContractWithOwners;
}>;

export const ContractsCollaborationSeisanKanrikunModal: React.FC<Props> = ({
  collaboratedSeisanKanrikunContractMutate,
  isCollaborationModalOpen,
  handleCollaborationModalClose,
  contract
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const [inputs, setInputs] = React.useState<SearchParams>(defaultSearchValue);
  const handleInputChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const handleInputDateChange = (name: string, value: string): void => {
    setInputs({
      ...inputs,
      [name]: value
    });
  };

  // APIリクエスト用の値
  const [searchInputs, setSearchInputs] =
    React.useState<SearchParams>(defaultSearchValue);

  // 一度でも検索をしたか確認
  const [searchedSeisanKanrikunContracts, setSearchedSeisanKanrikunContracts] =
    React.useState<boolean>(false);

  // 検索
  const {
    data: seisanKanrikunContracts,
    isLoading: seisanKanrikunContractsLoading,
    error: seisanKanrikunContractsError
  } = useSeisanKanrikunContracts().useIndex(searchInputs);

  const handleSearchSubmit = async (): Promise<void> => {
    setSearchedSeisanKanrikunContracts(true);
    setSearchInputs(inputs);
  };

  // 連携
  const handleCollaborate = async (
    seisanKanrikunContract: SeisanKanrikunContract
  ): Promise<void> => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`精算管理くん上の管理委託契約と連携して良いですか？`)
    ) {
      const response = await update(seisanKanrikunContract, contract.id);
      if (isSuccess(response)) {
        collaboratedSeisanKanrikunContractMutate?.();
        handleClose();
        toast.success('連携に成功しました');
        return;
      }
      toast.error(response.message);
    }
  };

  // オブジェクトのバリューに入力されているものが含まれているものがあるか確認
  const hasTruthyValue = Object.values(inputs).some((value) => value);

  return (
    <>
      {/* APIを複数回呼びたくないため */}
      {isOpen && (
        <ContractsSeisanKanrikunCollaborationDataDisplayModal
          contract={contract}
          handleClose={handleClose}
          isOpen={isOpen}
        />
      )}
      <Modal
        handleClose={handleCollaborationModalClose}
        isOpen={isCollaborationModalOpen}
        title="管理委託契約連携"
        useDefaultFooter={false}
      >
        <div className={Styles.Form}>
          <div className={Styles.InputLabel}>契約開始日</div>
          <div className={Styles.PeriodWrapper}>
            <InputDate
              onChange={(date: Readonly<Date>): void =>
                handleInputDateChange(
                  'startDateFrom',
                  convertQueryStringDate(date)
                )
              }
              popperDefault
              selected={convertDateWithNull(inputs.startDateFrom || null)}
            />
            ~
            <InputDate
              onChange={(date: Readonly<Date>): void =>
                handleInputDateChange(
                  'startDateTo',
                  convertQueryStringDate(date)
                )
              }
              popperDefault
              selected={convertDateWithNull(inputs.startDateTo || null)}
            />
          </div>
          <div className={Styles.InputLabel}>契約終了日</div>

          <div className={Styles.PeriodWrapper}>
            <InputDate
              onChange={(date: Readonly<Date>): void =>
                handleInputDateChange(
                  'endDateFrom',
                  convertQueryStringDate(date)
                )
              }
              popperDefault
              selected={convertDateWithNull(inputs.endDateFrom || null)}
            />
            ~
            <InputDate
              onChange={(date: Readonly<Date>): void =>
                handleInputDateChange('endDateTo', convertQueryStringDate(date))
              }
              popperDefault
              selected={convertDateWithNull(inputs.endDateTo || null)}
            />
          </div>
          <div className={Styles.InputLabel}>管理対象の名前</div>

          <Input
            name="title"
            onChange={handleInputChange}
            value={inputs.title}
          />
          <div className={Styles.InputLabel}>オーナー</div>
          <div>
            <InputSelect
              name="ykOwnerId"
              onChange={handleInputChange}
              value={inputs.ykOwnerId}
            >
              <option value="">選択なし</option>
              {contract.owners.map((owner: DeepReadonly<Owner>) => (
                <option
                  key={owner.id}
                  value={owner.id}
                >{`${owner.lastName}${owner.firstName}`}</option>
              ))}
            </InputSelect>
          </div>
          <Button
            className={Styles.SearchButton}
            disabled={!hasTruthyValue}
            height="small"
            onClick={handleSearchSubmit}
            variant="primary"
          >
            検索する
          </Button>
        </div>
        {!seisanKanrikunContractsLoading && seisanKanrikunContracts && (
          <div className={Styles.List}>
            {seisanKanrikunContracts.map(
              (seisanKanrikunContract: SeisanKanrikunContract) => (
                <div
                  key={seisanKanrikunContract.id}
                  className={Styles.SeisanKanrikunContract}
                >
                  <div>
                    <div className={Styles.SeisanKanrikunList}>
                      管理委託契約の管理対象: {seisanKanrikunContract.title}
                    </div>
                    <div className={Styles.SeisanKanrikunList}>
                      契約期間: {displayDate(seisanKanrikunContract.startDate)}{' '}
                      - {displayDate(seisanKanrikunContract.endDate)}
                    </div>
                  </div>
                  <button
                    className={Styles.CollaborateButton}
                    onClick={(): Promise<void> =>
                      handleCollaborate(seisanKanrikunContract)
                    }
                    type="button"
                  >
                    連携
                  </button>
                </div>
              )
            )}
          </div>
        )}
        {searchedSeisanKanrikunContracts &&
          !seisanKanrikunContractsLoading &&
          seisanKanrikunContracts?.length === 0 && (
            <div className={Styles.FetchResultText}>検索結果が0件です</div>
          )}
        {seisanKanrikunContractsLoading && (
          <div className={Styles.FetchResultText}>ロード中</div>
        )}
        {seisanKanrikunContractsError && (
          <div className={Styles.FetchResultText}>
            データ取得中にエラーが発生しました
          </div>
        )}
        <ContractsCollaborationSeisanKanrikunNewLink
          handleCollaborateModalClose={handleCollaborationModalClose}
          handleCollaborationDataDisplayModalOpen={handleOpen}
        />
      </Modal>
    </>
  );
};
