import React from 'react';
import { displayName, Owner } from '~/models/owner';
import { RiEdit2Line } from 'react-icons/ri';
import { useRouter } from 'next/router';
import { PATH } from '~/pages/owners';
import { PATH as EditPath } from '~/pages/owners/[id]/edit';
import { Breadcrumbs, BreadcrumbsItem, Button } from '@itandi/itandi-bb-ui';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  owner: Owner;
}>;

export const NewOwnersTitleHeader: React.FC<Props> = ({ owner }: Props) => {
  const router = useRouter();
  const { id = null } = useRouter().query;

  return (
    <div className={Styles.TitleHeader}>
      <nav className={Styles.BreadcrumbsWrapper}>
        <Breadcrumbs>
          <BreadcrumbsItem isText>家主管理</BreadcrumbsItem>
          <BreadcrumbsItem
            onClick={(): void => {
              router.push(PATH).then();
            }}
          >
            家主一覧
          </BreadcrumbsItem>
          <BreadcrumbsItem isText>家主詳細</BreadcrumbsItem>
        </Breadcrumbs>
      </nav>
      <div className={Styles.TitleBox}>
        <h1 className={Styles.Title}>{displayName(owner)}</h1>
        <Button
          height="small"
          onClick={(): void => {
            router.push(EditPath(Number(id))).then();
          }}
          startIcon={<RiEdit2Line />}
          variant="secondary"
        >
          編集
        </Button>
      </div>
    </div>
  );
};
