import React from 'react';
import { TabMode } from '~/components/specifics/contracts/[id]/components/Container';
import { ContractsContentsBasic } from '~/components/specifics/contracts/[id]/components/Contents/Basic';
import { ContractsRemittingContents } from '~/components/specifics/contracts/[id]/components/Contents/Remitting/Contents';
import { ContractsPrintContents } from '~/components/specifics/contracts/[id]/components/Contents/Print/Contents';
import { ContractsProcessContents } from '~/components/specifics/contracts/[id]/components/Contents/Process/Contents';
import { ContractWithOwners } from '~/models/contract';
import { ContractsFilesContents } from '~/components/specifics/contracts/[id]/components/Contents/Files/Contents';
import { KeyedMutator } from 'swr';

type Props = DeepReadonly<{
  tabMode: TabMode;
  contract: ContractWithOwners;
  mutate: KeyedMutator<ContractWithOwners> | undefined;
}>;

export const ContractContents: React.FC<Props> = ({
  tabMode,
  contract,
  mutate
}: Props) => (
  <div>
    {tabMode === 'basic' && (
      <ContractsContentsBasic contract={contract} contractMutate={mutate} />
    )}
    {tabMode === 'remitting' && (
      <ContractsRemittingContents contract={contract} />
    )}
    {tabMode === 'process' && (
      <ContractsProcessContents archived={contract.archived} mutate={mutate} />
    )}
    {tabMode === 'print' && <ContractsPrintContents />}
    {tabMode === 'files' && <ContractsFilesContents contract={contract} />}
  </div>
);
