import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { RiAddBoxLine } from 'react-icons/ri';
import {
  InputSelect,
  Input,
  Button,
  SubHeadLine,
  ComboBox
} from '@itandi/itandi-bb-ui';
import { useSeisanKanrikunBanks } from '~/hooks/api/useSeisanKanrikunBanks';
import { useSeisanKanrikunBankBranches } from '~/hooks/api/useSeisanKanrikunBankBranches';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import Styles from '../index.module.scss';

type BankAccountRowProps = {
  index: number;
  fieldId: string;
};

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const BankAccountRow: React.FC<BankAccountRowProps> = ({ index, fieldId }) => {
  const { register, watch, setValue, formState } =
    useFormContext<OwnerForEdit>();
  const { errors } = formState;
  const bankSearch = watch('bankSearch') ?? '';

  const { useIndex: useBanksIndex } = useSeisanKanrikunBanks();
  const { data: banks } = useBanksIndex({ initial: bankSearch });

  const bankCode = watch(`bankAccounts.${index}.bankCode`) || '';

  const bankBranchSearch = watch('bankBranchSearch') ?? '';

  const { useIndex: useBankBranchesIndex } = useSeisanKanrikunBankBranches();

  const { data: bankBranches } = useBankBranchesIndex({
    bankCode,
    initial: bankBranchSearch
  });

  return (
    <FormBlockRow
      key={fieldId}
      errorMessage={errors.bankAccounts?.[index]?.message}
      label={`口座${index + 1}`}
    >
      <div className={Styles.BankAccountInnerBlock}>
        <ComboBox
          isInvalid={!!errors.bankAccounts?.[index]?.bankCode}
          isMulti={false}
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(option): void => {
            setValue(`bankAccounts.${index}.bankCode`, option?.value || null);
          }}
          options={
            banks?.map((bank) => ({
              label: `(${bank.code})${bank.displayName}`,
              value: bank.code
            })) ?? []
          }
          placeholder="銀行を選択"
        />
        {errors.bankAccounts?.[index]?.bankCode?.message && (
          <p className={Styles.ErrorMessage}>
            {errors.bankAccounts[index]?.bankCode?.message}
          </p>
        )}
      </div>
      <div className={Styles.BankAccountInnerBlock}>
        {!bankCode ? (
          <div className={Styles.BankAccountBankBranchBox}>
            <span className={Styles.BankAccountBankBranchBoxInner}>
              銀行の支店を選択するために、銀行を選択してください
            </span>
          </div>
        ) : (
          <ComboBox
            isInvalid={!!errors.bankAccounts?.[index]?.branchCode}
            isMulti={false}
            // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
            onChange={(option): void => {
              setValue(
                `bankAccounts.${index}.branchCode`,
                option?.value || null
              );
            }}
            options={
              bankBranches?.map((branch) => ({
                label: `(${branch.code})${branch.name}`,
                value: branch.code
              })) ?? []
            }
            placeholder="支店を選択"
          />
        )}
        {errors.bankAccounts?.[index]?.branchCode?.message && (
          <p className={Styles.ErrorMessage}>
            {errors.bankAccounts[index]?.branchCode?.message}
          </p>
        )}
      </div>
      <div className={Styles.DepositRow}>
        <div className={Styles.BankAccountInnerBlock}>
          <div className={Styles.DepositLeftInput}>
            <InputSelect
              isInvalid={!!errors.bankAccounts?.[index]?.depositKind}
              placeholder="種類を選択"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`bankAccounts.${index}.depositKind`)}
            >
              <option value="">選択なし</option>
              <option value="resident">普通口座</option>
              <option value="lessee">当座預金</option>
              <option value="joint_guarantor">貯蓄預金</option>
            </InputSelect>
            {errors.bankAccounts?.[index]?.depositKind?.message && (
              <p className={Styles.ErrorMessage}>
                {errors.bankAccounts[index]?.depositKind?.message}
              </p>
            )}
          </div>
        </div>
        <div className={Styles.DepositRightInput}>
          <Input
            isInvalid={!!errors.bankAccounts?.[index]?.accountNumber}
            placeholder="例） 0000001"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register(`bankAccounts.${index}.accountNumber`)}
          />
          {errors.bankAccounts?.[index]?.accountNumber?.message && (
            <p className={Styles.ErrorMessage}>
              {errors.bankAccounts[index]?.accountNumber?.message}
            </p>
          )}
        </div>
      </div>
      <div className={Styles.BankAccountInnerBlock}>
        <Input
          isInvalid={!!errors.bankAccounts?.[index]?.accountHolder}
          placeholder="ｲﾀﾝｼﾞ ﾀﾛｳ"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(`bankAccounts.${index}.accountHolder`)}
        />
        <div className={Styles.Description}>
          1文字以上、カタカナ、半角数字(0~9)、半角英字大文字(A~Z)、記号 ().-
        </div>
        {errors.bankAccounts?.[index]?.accountHolder?.message && (
          <p className={Styles.ErrorMessage}>
            {errors.bankAccounts[index]?.accountHolder?.message}
          </p>
        )}
      </div>
      <div className={Styles.BankAccountLastInnerBlock}>
        <InputSelect
          color="#000"
          fullWidth
          isInvalid={!!errors.bankAccounts?.[index]?.remittanceFeeSettingId}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(`bankAccounts.${index}.remittanceFeeSettingId`)}
        >
          <option value="">選択なし</option>
          <option value="remitance_fee1">手数料 1</option>
          <option value="remitance_fee2">手数料 2</option>
          <option value="remitance_fee3">手数料 3</option>
        </InputSelect>
      </div>
    </FormBlockRow>
  );
};

export const OwnersEditorBlockBankAccount: React.FC = () => {
  const { control } = useFormContext<OwnerForEdit>();

  const { fields, append } = useFieldArray<OwnerForEdit>({
    control,
    name: 'bankAccounts'
  });

  if (fields.length === 0) {
    append(
      {
        bankCode: '',
        branchCode: '',
        depositKind: '',
        accountNumber: '',
        accountHolder: '',
        remittanceFeeSettingId: ''
      },
      { shouldFocus: false }
    );
  }

  const handleAddOption = (): void => {
    append(
      {
        bankCode: '',
        branchCode: '',
        depositKind: '',
        accountNumber: '',
        accountHolder: '',
        remittanceFeeSettingId: ''
      },
      { shouldFocus: false }
    );
  };

  return (
    <FormBlock>
      <div className={Styles.Title}>
        <SubHeadLine title="銀行口座" />
      </div>

      {/* eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types */}
      {fields.map((field, index) => (
        <BankAccountRow key={field.id} fieldId={field.id} index={index} />
      ))}
      <FormBlockRow label={`口座${fields.length + 1}`}>
        <span className={Styles.CreateButton}>
          <Button
            height="small"
            onClick={handleAddOption}
            startIcon={<RiAddBoxLine className={Styles.CreateIcon} />}
            variant="secondary"
          >
            追加
          </Button>
        </span>
      </FormBlockRow>
    </FormBlock>
  );
};
