import React, { ReactNode } from 'react';
import { RiDraftLine } from 'react-icons/ri';
import {
  MainHeadLine,
  DefinitionListItem,
  DefinitionListItemData,
  DefinitionListItemHeading,
  DefinitionList
} from '@itandi/itandi-bb-ui';
import { NewOwnersTitleHeader } from '~/components/specifics/owners/[id]/components/NewTitleHeader';
import { OwnersContentsBasicIndividual } from '~/components/specifics/owners/[id]/components/NewContents/Basic/Individual';
import { OwnersContentsBasicCorp } from '~/components/specifics/owners/[id]/components/NewContents/Basic/Corp';
import { OwnersContentsBasicTel } from '~/components/specifics/owners/[id]/components/NewContents/Basic/Tel';
import { OwnersContentsBasicEmail } from '~/components/specifics/owners/[id]/components/NewContents/Basic/Email';
import { OwnersContentsBasicAddress } from '~/components/specifics/owners/[id]/components/NewContents/Basic/Address';
import { displayDestName, OwnerWithContracts } from '~/models/owner';
import { OwnersContentsBasicCustomField } from '~/components/specifics/owners/[id]/components/NewContents/Basic/OwnerCustomField';
import { BankAccount } from '~/components/specifics/owners/[id]/components/NewContents/Basic/BankAccount';
import { displayDate } from '~/utils/dateUtils';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  owner: OwnerWithContracts;
}>;

export const getContentOrEmptyLabel = (content: string | null): ReactNode => {
  if (content === null || content === '') {
    return <span className={Styles.Empty}>未登録</span>;
  }
  return <span>{content}</span>;
};

export const NewOwnersContentsBasic: React.FC<Props> = ({ owner }: Props) => (
  <>
    <NewOwnersTitleHeader owner={owner} />
    <div className={Styles.Card} id="basicSection">
      <div className={Styles.CardTitleWrapper}>
        <MainHeadLine
          description="基本情報"
          descriptionColor="Sub"
          icon={RiDraftLine}
          title="家主概要"
        />
      </div>
      <div className={Styles.BasicBox}>
        <div className={Styles.LeftWrapper}>
          <DefinitionList>
            {owner.corp ? (
              <OwnersContentsBasicCorp />
            ) : (
              <OwnersContentsBasicIndividual />
            )}
            <OwnersContentsBasicTel />
            <OwnersContentsBasicEmail />
            <OwnersContentsBasicAddress />
          </DefinitionList>
        </div>
        <div className={Styles.RightWrapper}>
          <div>
            <DefinitionList>
              <DefinitionListItem>
                <DefinitionListItemHeading width="50%">
                  反社チェック実施可否
                </DefinitionListItemHeading>
                <DefinitionListItemData>
                  {owner.antisocialCheckDone ? '実施済' : '未実施'} (
                  {getContentOrEmptyLabel(
                    owner.antisocialCheckDate
                      ? displayDate(owner.antisocialCheckDate)
                      : null
                  )}
                  )
                </DefinitionListItemData>
              </DefinitionListItem>
              <DefinitionListItem>
                <DefinitionListItemHeading>
                  家主管理番号
                </DefinitionListItemHeading>
                <DefinitionListItemData>
                  {owner.ownerKey}
                </DefinitionListItemData>
              </DefinitionListItem>
              <DefinitionListItem>
                <DefinitionListItemHeading>
                  適格請求書発行事業者 登録番号
                </DefinitionListItemHeading>
                <DefinitionListItemData>
                  {owner.qualifiedInvoiceIssuerRegistrationNumber}
                </DefinitionListItemData>
              </DefinitionListItem>
            </DefinitionList>
          </div>
          <div>
            <DefinitionList>
              <DefinitionListItem>
                <DefinitionListItemHeading width="50%">
                  送金用氏名（姓 名）
                </DefinitionListItemHeading>
                <DefinitionListItemData>
                  {getContentOrEmptyLabel(
                    `${owner.destLastName} ${owner.destFirstName}`
                  )}
                </DefinitionListItemData>
              </DefinitionListItem>
              <DefinitionListItem>
                <DefinitionListItemHeading>
                  送金用氏名の敬称
                </DefinitionListItemHeading>
                <DefinitionListItemData>
                  {getContentOrEmptyLabel(displayDestName(owner.destLabel))}
                </DefinitionListItemData>
              </DefinitionListItem>
            </DefinitionList>
          </div>
          <div>
            <DefinitionList>
              <DefinitionListItem>
                <DefinitionListItemHeading width="50%">
                  担当者
                </DefinitionListItemHeading>
                {owner.staff ? (
                  <DefinitionListItemData>
                    {getContentOrEmptyLabel(owner.staff.name)}
                  </DefinitionListItemData>
                ) : (
                  <DefinitionListItemData>
                    {getContentOrEmptyLabel('')}
                  </DefinitionListItemData>
                )}
              </DefinitionListItem>
              <DefinitionListItem>
                <DefinitionListItemHeading>登録日時</DefinitionListItemHeading>
                <DefinitionListItemData>
                  {getContentOrEmptyLabel(displayDate(owner.updatedAt))}
                </DefinitionListItemData>
              </DefinitionListItem>
            </DefinitionList>
          </div>
        </div>
      </div>
      <div className={Styles.BottomWrapper}>
        <DefinitionList>
          <DefinitionListItem>
            <DefinitionListItemHeading width={200}>
              備考
            </DefinitionListItemHeading>
            {owner.staff ? (
              <DefinitionListItemData>
                {getContentOrEmptyLabel(owner.staff.name)}
              </DefinitionListItemData>
            ) : (
              <DefinitionListItemData>
                {getContentOrEmptyLabel('')}
              </DefinitionListItemData>
            )}
          </DefinitionListItem>
        </DefinitionList>
      </div>
    </div>
    <BankAccount />
    <OwnersContentsBasicCustomField />
  </>
);
