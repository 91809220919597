import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import {
  Input,
  InputRadio,
  SubHeadLine,
  InputDate
} from '@itandi/itandi-bb-ui';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import { convertDateWithNull } from '~/utils/dateUtils';
import Styles from '../index.module.scss';

type Props = Readonly<{
  isFullNameForm: boolean;
  setIsFullNameForm: (value: boolean) => void;
}>;

export const NewOwnersEditorBlockIndividual: React.FC<Props> = ({
  isFullNameForm,
  setIsFullNameForm
}: Props) => {
  const { register, formState, setValue, watch, getValues } =
    useFormContext<OwnerForEdit>();
  const { errors } = formState;

  const handleNameToggle = (value: boolean): void => {
    setIsFullNameForm(value);

    if (value) {
      const lastName = getValues('lastName') || '';
      const firstName = getValues('firstName') || '';
      const lastNameKana = getValues('lastNameKana') || '';
      const firstNameKana = getValues('firstNameKana') || '';

      setValue('fullName', `${lastName} ${firstName}`);
      setValue('fullNameKana', `${lastNameKana} ${firstNameKana}`);
    } else {
      const [lastName = '', firstName = ''] = (
        getValues('fullName') || ''
      ).split(' ');
      const [lastNameKana = '', firstNameKana = ''] = (
        getValues('fullNameKana') || ''
      ).split(' ');

      setValue('lastName', lastName);
      setValue('firstName', firstName);
      setValue('lastNameKana', lastNameKana);
      setValue('firstNameKana', firstNameKana);
    }
  };

  return (
    <FormBlock>
      <div className={Styles.Title}>
        <SubHeadLine title="家主情報" />
      </div>
      <FormBlockRow label="氏名の分割" required>
        <div className={Styles.InputRadioGroup}>
          <InputRadio
            checked={!isFullNameForm}
            label="氏と名に分割"
            // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
            onChange={(): void => handleNameToggle(false)}
            value="false"
          />
          <InputRadio
            checked={isFullNameForm}
            label="一括入力"
            // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
            onChange={(): void => handleNameToggle(true)}
            value="true"
          />
        </div>
      </FormBlockRow>
      <FormBlockRow label="氏名" required>
        <div className={Styles.NameInputGroup}>
          {isFullNameForm ? (
            <div className={Styles.DefaultRow}>
              <div className={Styles.NameInnerBlock}>
                <Input
                  isInvalid={errors.fullName != null}
                  placeholder="例）家主 太郎"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('fullName')}
                />
                {errors.fullName != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.fullName.message}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={Styles.DefaultRow}>
              <div className={Styles.NameInnerBlock}>
                <Input
                  isInvalid={errors.lastName != null}
                  placeholder="例）家主"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('lastName')}
                />
                {errors.lastName != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.lastName.message}
                  </div>
                )}
              </div>
              <div className={Styles.NameInnerBlock}>
                <Input
                  isInvalid={errors.firstName != null}
                  placeholder="例）太郎"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('firstName')}
                />
                {errors.firstName != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.firstName.message}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </FormBlockRow>

      <FormBlockRow label="氏名カナ">
        <div className={Styles.NameInputGroup}>
          {isFullNameForm ? (
            <div className={Styles.DefaultRow}>
              <div className={Styles.NameInnerBlock}>
                <Input
                  isInvalid={errors.fullNameKana != null}
                  placeholder="例）ヤヌシ タロウ"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('fullNameKana')}
                />
                {errors.fullNameKana != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.fullNameKana.message}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={Styles.DefaultRow}>
              <div className={Styles.NameInnerBlock}>
                <Input
                  placeholder="例）ヤヌシ"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('lastNameKana')}
                />
                {errors.lastNameKana != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.lastNameKana.message}
                  </div>
                )}
              </div>
              <div className={Styles.NameInnerBlock}>
                <Input
                  placeholder="例）タロウ"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('firstNameKana')}
                />
                {errors.firstNameKana != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.firstNameKana.message}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.sex?.message} label="性別">
        <div className={Styles.InputRadioGroup}>
          <InputRadio
            label="男性"
            value="man"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('sex')}
          />
          <InputRadio
            label="女性"
            value="woman"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('sex')}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow label="生年月日">
        <div className={Styles.DefaultRow}>
          <InputDate
            className={Styles.BirthdayInput}
            onChange={(date: Readonly<Date> | null): void => {
              setValue('birthday', date === null ? '' : date.toString());
            }}
            placeholder="年 / 月 / 日"
            value={convertDateWithNull(watch('birthday'))}
          />
        </div>
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.job?.message} label="職業">
        <div className={Styles.DefaultRow}>
          <Input
            placeholder="例）会社員"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('job')}
          />
        </div>
      </FormBlockRow>
    </FormBlock>
  );
};
