import React, { ReactNode } from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
  title: string;
  subTitle?: string;
  icon: ReactNode;
  buttons?: ReactNode;
}>;

export const ListCard: React.FC<Props> = ({
  children,
  title,
  subTitle,
  icon,
  buttons
}: Props) => (
  <div className={Styles.Card}>
    <div className={Styles.CardTitleWrapper}>
      <span className={Styles.CardTitleIcon}>{icon}</span>
      <div>
        <h3 className={Styles.CardTitle}>{title}</h3>
        {subTitle && <span className={Styles.CardSubTitle}>{subTitle}</span>}
      </div>
      {buttons && <span className={Styles.CardButtonWrapper}>{buttons}</span>}
    </div>
    <div>{children}</div>
  </div>
);
