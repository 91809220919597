import React from 'react';
import { displayName, Owner } from '~/models/owner';
import { useRouter } from 'next/router';
import { PATH } from '~/pages/owners';
import { Breadcrumbs, BreadcrumbsItem } from '@itandi/itandi-bb-ui';
import { useFeatureFlag } from '~/components/specifics/settings/hooks/useFeatureFlag';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  owner: Owner;
}>;

export const OwnersTitleHeader: React.FC<Props> = ({ owner }: Props) => {
  const [useExperimentalOwnerFlag] = useFeatureFlag('useExperimentalOwnerFlag');

  const router = useRouter();

  if (useExperimentalOwnerFlag) {
    return null;
  }

  return (
    <div className={Styles.TitleHeader}>
      <nav className={Styles.BreadcrumbsWrapper}>
        <Breadcrumbs>
          <BreadcrumbsItem
            onClick={(): void => {
              router.push(PATH).then();
            }}
          >
            家主一覧
          </BreadcrumbsItem>
          <BreadcrumbsItem isText>家主詳細</BreadcrumbsItem>
        </Breadcrumbs>
      </nav>
      <h1 className={Styles.Title}>{displayName(owner)}</h1>
    </div>
  );
};
