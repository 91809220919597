import React, { useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { Scrollspy, ScrollspyItem } from '@itandi/itandi-bb-ui';
import { RiPriceTag3Line } from 'react-icons/ri';
import {
  defaultOwnerValue,
  Owner,
  OwnerForEdit,
  validationSchema
} from '~/models/owner';
import { OwnersEditorFooter } from '~/components/specifics/owners/components/Editor/Footer';
import { OwnersEditorBlockBasic } from '~/components/specifics/owners/components/Editor/Block/Basic';
import { OwnersEditorBlockCorp } from '~/components/specifics/owners/components/Editor/Block/Corp';
import { OwnersEditorBlockContact } from '~/components/specifics/owners/components/Editor/Block/Contact';
import { OwnersEditorBlockOther } from '~/components/specifics/owners/components/Editor/Block/Other';
import { OwnersEditorBlockBankAccount } from '~/components/specifics/owners/components/Editor/Block/BankAccount';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOwnerCustomFieldSettings } from '~/hooks/api/useOwnerCustomFieldSettings';
import { OwnerCustomValue } from '~/models/ownerCustomValue';
import { OwnersEditorBlockCustomField } from '~/components/specifics/owners/components/Editor/Block/OwnerCustomField';
import { useFeatureFlag } from '~/components/specifics/settings/hooks/useFeatureFlag';
import { NewOwnersEditorBlockBasic } from '~/components/specifics/owners/components/NewEditor/Block/Basic';
import { NewOwnersEditorBlockCorp } from '~/components/specifics/owners/components/NewEditor/Block/Corp';
import { NewOwnersEditorBlockContact } from '~/components/specifics/owners/components/NewEditor/Block/Contact';
import { NewOwnersEditorBlockOther } from '~/components/specifics/owners/components/NewEditor/Block/Other';
import { NewOwnersEditorBlockIndividual } from '~/components/specifics/owners/components/NewEditor/Block/Individual';
import { OwnersEditorBlockIndividual } from '../../../specifics/owners/components/Editor/Block/Individual';
import Styles from './index.module.scss';
import { OwnersEditorBlockAntisocialCheck } from '../../../specifics/owners/components/Editor/Block/AntisocialCheck';

type Props = DeepReadonly<{
  owner?: Owner | null;
}>;

export type EditorMode = 'new' | 'edit';

export const OwnersEditor: React.FC<Props> = ({ owner = null }: Props) => {
  const editorMode: EditorMode = React.useMemo(
    () => (owner === null ? 'new' : 'edit'),
    [owner]
  );
  const { data: ownerCustomFieldSettings } =
    useOwnerCustomFieldSettings().useIndex();
  const [isFullNameForm, setIsFullNameForm] = useState<boolean>(false);
  const methods: UseFormReturn<DeepReadonly<OwnerForEdit>> = useForm<
    DeepReadonly<OwnerForEdit>
  >({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    context: { isFullNameForm },
    defaultValues: owner
      ? {
          ...owner,
          ownerCustomFieldValues: owner?.ownerCustomValues.reduce(
            (
              acc: Readonly<{ [field in string]: string | boolean | number }>,
              currentValue: DeepReadonly<OwnerCustomValue>
            ) => ({
              ...acc,
              [currentValue.fieldKey]: currentValue.value
            }),
            {}
          )
        }
      : defaultOwnerValue()
  });
  const { watch } = methods;

  const [useExperimentalOwnerFlag] = useFeatureFlag('useExperimentalOwnerFlag');

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <div className={Styles.FormContainer}>
          <div className={Styles.EditorSection}>
            {useExperimentalOwnerFlag ? (
              <>
                <div id="basicSection">
                  <NewOwnersEditorBlockBasic />
                </div>
                <div id="individualSection">
                  {watch('corp') ? (
                    <NewOwnersEditorBlockCorp />
                  ) : (
                    <NewOwnersEditorBlockIndividual
                      isFullNameForm={isFullNameForm}
                      setIsFullNameForm={setIsFullNameForm}
                    />
                  )}
                </div>
                <div id="antisocialCheckSection">
                  <OwnersEditorBlockAntisocialCheck />
                </div>
                <div id="contactSection">
                  <NewOwnersEditorBlockContact />
                </div>
                <div id="otherSection">
                  <NewOwnersEditorBlockOther />
                </div>
                {editorMode !== 'edit' && (
                  <div id="BankAccountSection">
                    <OwnersEditorBlockBankAccount />
                  </div>
                )}
              </>
            ) : (
              <>
                <OwnersEditorBlockBasic />
                {watch('corp') ? (
                  <OwnersEditorBlockCorp />
                ) : (
                  <OwnersEditorBlockIndividual
                    isFullNameForm={isFullNameForm}
                    setIsFullNameForm={setIsFullNameForm}
                  />
                )}
                <OwnersEditorBlockAntisocialCheck />
                <OwnersEditorBlockContact />
                <OwnersEditorBlockOther />
              </>
            )}

            {ownerCustomFieldSettings !== null && (
              <div id="customFieldSection">
                <OwnersEditorBlockCustomField
                  ownerCustomFieldSettings={ownerCustomFieldSettings}
                />
              </div>
            )}
          </div>
          {useExperimentalOwnerFlag && (
            <div className={Styles.ScrollspySection}>
              <Scrollspy title="目次">
                <ScrollspyItem
                  Icon={RiPriceTag3Line}
                  label="基本情報"
                  target="#basicSection"
                />
                <ScrollspyItem
                  Icon={RiPriceTag3Line}
                  label="家主情報"
                  target="#individualSection"
                />
                <ScrollspyItem
                  Icon={RiPriceTag3Line}
                  label="反社チェック"
                  target="#antisocialCheckSection"
                />
                <ScrollspyItem
                  Icon={RiPriceTag3Line}
                  label="連絡先"
                  target="#contactSection"
                />
                <ScrollspyItem
                  Icon={RiPriceTag3Line}
                  label="その他情報"
                  target="#otherSection"
                />
                {editorMode !== 'edit' ? (
                  <ScrollspyItem
                    Icon={RiPriceTag3Line}
                    label="銀行口座"
                    target="#BankAccountSection"
                  />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
                {ownerCustomFieldSettings !== null ? (
                  <ScrollspyItem
                    Icon={RiPriceTag3Line}
                    label="補足情報"
                    target="#customFieldSection"
                  />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
              </Scrollspy>
            </div>
          )}
        </div>
        <OwnersEditorFooter
          editorMode={editorMode}
          isFullNameForm={isFullNameForm}
        />
      </FormProvider>
    </div>
  );
};
