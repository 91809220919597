import React, { ReactNode } from 'react';
import {
  Chip,
  DefinitionListItem,
  DefinitionListItemData,
  DefinitionListItemHeading,
  colors
} from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import { emailNumbers, getEmail, Owner } from '~/models/owner';
import Styles from '../index.module.scss';

const getEmailNodes = (owner: Owner): ReactNode[] => {
  const result: ReactNode[] = [];
  emailNumbers.forEach((num: 1 | 2 | 3): ReactNode => {
    const email = getEmail(owner, num);
    if (email === null || email === '') {
      return;
    }
    result.push(
      <div key={num}>
        <span>
          <a href={`mailto:${email}`}>{email}</a>
        </span>
        {num === 1 && (
          <span className={Styles.Additional}>
            <Chip
              background={colors['gray-200']}
              borderColor={colors['itandi-white']}
              label="メイン"
            />
          </span>
        )}
      </div>
    );
  });
  return result;
};

export const OwnersContentsBasicEmail: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }
  return (
    <DefinitionListItem>
      <DefinitionListItemHeading>メール</DefinitionListItemHeading>
      <DefinitionListItemData>
        <>
          {getEmailNodes(owner).length === 0 && (
            <span className={Styles.Empty}>未登録</span>
          )}
          {getEmailNodes(owner)}
        </>
      </DefinitionListItemData>
    </DefinitionListItem>
  );
};
